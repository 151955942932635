import { AppProps } from 'next/app'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import { Fragment, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/node'

import { useApollo } from 'lib/apollo'

import { BASE_URI, GA_TRACKING_ID } from 'core/constants'

import * as colors from 'ressources/colors'

import '../styles/index.css'
import '../styles/global.css'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

export default function App({ Component, pageProps }: AppProps) {
  const client = useApollo(pageProps.initialApolloState)

  useEffect(() => {
    if (typeof window !== undefined) {
      require('browser-update')({
        required: { e: -6, f: -6, o: -3, s: -6, c: -6 },
        insecure: false,
        style: 'bottom',
        api: 2020.12,
        text_for_i: {
          msg:
            "Notre site potico.fr n'est pas en mesure d'offrir une expérience optimale et sécurisée sur ({brow_name}).",
          msgmore:
            'Nous vous invitons à utiliser un navigateur répondant aux normes du Web et de sécurité actuelles.',
          bupdate: 'Installer un autre navigateur',
        },
      })
    }
  }, [])

  return (
    <ApolloProvider client={client}>
      <Head>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/iconapp-180x180.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content={colors.BRAND_50} />
        <meta name="apple-mobile-web-app-status-bar-style" content={colors.BRAND_50} />
        <script
          src="https://config.metomic.io/config.js?id=prj:6771b5ce-f35c-4cbf-996c-0507dfb3f67d"
          crossOrigin="true"
          charSet="utf-8"
        ></script>
        <script
          src="https://consent-manager.metomic.io/embed.js"
          crossOrigin="true"
          charSet="utf-8"
        ></script>
        {process.env.NODE_ENV === 'production' && (
          <Fragment>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />
            <script>window[`ga-disable-${GA_TRACKING_ID}`] = true</script>
            <script type="text/x-metomic" data-micropolicy="analytics">
              window[`ga-disable-${GA_TRACKING_ID}`] = false
            </script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GA_TRACKING_ID}', {
                      page_path: window.location.pathname,
                    });
                  `,
              }}
            />
          </Fragment>
        )}
      </Head>
      <DefaultSeo
        openGraph={{
          type: 'website',
          locale: 'fr_FR',
          url: BASE_URI,
          site_name: 'Potico',
          images: [
            {
              url: `${BASE_URI}/logo_potico_squaredark.png`,
              width: 200,
              height: 200,
              alt: 'open graph Potico logo',
            },
          ],
        }}
        twitter={{
          site: BASE_URI,
          cardType: 'summary_large_image',
        }}
      />
      <Component {...pageProps} />
    </ApolloProvider>
  )
}
